import { WorkflowActionTypes } from '../actions/WorkflowActions/actionType';
import { nextStateObject } from 'utility/utility';

export const initialState = {
  workflows: [],
  workflowRuns: [],
  workflowShares:[],
  workflowSharesPageCount: 1,
  workflowsPageCount: 1,
  currentWorkflow: null,
  workflowTaskInstances: [],
  workflowTaskInstanceLogs: '',
  projectWorkflows: [],
  errors: [],
  latestTasks: {},
  visualWorkflow: null,
  workflowData: [],
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps
  }
};

export const workflowReducer = (state = initialState, action) => {
  let stateObject, nextState;
  switch (action.type) {
    case WorkflowActionTypes.GET_WORKFLOWS_FULFILLED:
      return updateObject(state, { workflows: action.payload.workflows, workflowsPageCount: action.payload.pageCount});
    case WorkflowActionTypes.GET_WORKFLOWDATA_FULFILLED:
      return updateObject(state, { workflowData: action.payload.workflowData });
    case WorkflowActionTypes.GET_WORKFLOW_FULFILLED:
      return updateObject(state, {currentWorkflow: action.payload.workflow});
    case WorkflowActionTypes.GET_VISUAL_WORKFLOW_FULFILLED:
      return updateObject(state, {visualWorkflow: action.payload.workflow});
    case WorkflowActionTypes.UPDATE_WORKFLOW_FULFILLED:
      stateObject = {workflows: [...state.workflows]}
      nextState = nextStateObject(stateObject, action.payload);
      return updateObject(state, {
        workflows: nextState.workflows,
      });
    case WorkflowActionTypes.PAUSE_WORKFLOW_FULFILLED:
      stateObject = {workflows: [...state.workflows]}
      nextState = nextStateObject(stateObject, action.payload);
      return updateObject(state, { workflows: nextState.workflows });
    case WorkflowActionTypes.CREATE_WORKFLOW_FULFILLED:
      stateObject = {workflows: [...state.workflows]}
      nextState = nextStateObject(stateObject, action.payload);
      return updateObject(state, {
        workflows: nextState.workflows
      });
    case WorkflowActionTypes.CREATE_VISUAL_WORKFLOW_FULFILLED:
      stateObject = {workflows: [...state.workflows]}
      nextState = nextStateObject(stateObject, action.payload);
      return updateObject(state, {
        workflows: nextState.workflows,
        currentWorkflow: action.payload.workflowResp
      });
    case WorkflowActionTypes.GET_WORKFLOW_RUNS_FULFILLED:
      return updateObject(state, { workflowRuns: action.payload.workflowdata });
    case WorkflowActionTypes.GET_WORKFLOW_TASK_INSTANCES_FULFILLED:
      return updateObject(state, {workflowTaskInstances: action.payload.taskInstances.task_instances})
    case WorkflowActionTypes.GET_WORKFLOW_TASK_INSTANCE_LOGS_FULFILLED:
      return updateObject(state, {workflowTaskInstanceLogs: action.payload.taskInstanceLogs.content})
    case WorkflowActionTypes.GET_PROJECT_WORKFLOWS_FULFILLED:
      return updateObject(state, {projectWorkflows: action.payload.workflows});
    case WorkflowActionTypes.GET_SHARED_PROJECT_WORKFLOWS_FULFILLED:
      return updateObject(state, {projectWorkflows: [...state.projectWorkflows, ...action.payload.entityShares]});
    case WorkflowActionTypes.GET_IMPORT_ERRORS_FULFILLED:
      return updateObject(state, {errors: action.payload.importErrors});
    case WorkflowActionTypes.GET_LATEST_TASKS_FULFUILLED:
      return updateObject(state, {latestTasks: action.payload.task_instances});
    case WorkflowActionTypes.GET_WORKFLOW_SHARES_FULFILLED:
      return updateObject(state, { workflowShares: action.payload.entityShares, workflowSharesPageCount: action.payload.pageCount})
    case WorkflowActionTypes.REMOVE_WORKFLOW:
      return updateObject(state, {workflows: state.workflows.filter((workflow) => workflow.id !== action.payload) })
    default: return state;
  }
}