import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { projectReducer } from "./projectReducer";
import { projectsReducer } from "./projectsReducer";
import { notebookReducer } from "./notebookReducer";
import { appReducer } from "./appReducer";
import { awsReducer } from "./awsReducer";
import { dataSetReducer } from "./dataSetReducer";
import { commentsReducer } from "./commentsReducer";
import { flightingReducer } from "./flightingReducer";
import { organizationReducer } from "./organizationReducer";
import { userReducer } from "./userReducer";
import { directoriesReducer } from "./directoryReducer";
import { web3Reducer } from "./web3Reducer";
import { workflowReducer } from "./workflowReducer";
import { sharesReducer } from "./sharesReducer";
import { metricsReducer } from "./metricsReducer";
import { userActivitiesReducer }  from "./userActivitiesReducer";
import { clusterReducer } from "./clusterReducer";

const reducers = combineReducers({
  authReducer,
  projectReducer,
  projectsReducer,
  notebookReducer,
  appReducer,
  awsReducer,
  dataSetReducer,
  commentsReducer,
  flightingReducer,
  organizationReducer,
  userReducer,
  directoriesReducer,
  web3Reducer,
  workflowReducer,
  sharesReducer,
  metricsReducer,
  userActivitiesReducer,
  clusterReducer,
});

export default reducers;
