import React from "react";
import DatasetForm from "components/DataSets/Form";

export const AddDataset = ({history}) => {
  const defaultForm = {
    name: "",
    description: "",
    file: []
  };
  
  return <DatasetForm defaultForm={defaultForm} history={history} />;
};

export default AddDataset;
