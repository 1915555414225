import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/ProjectsActions";
import { getContainerLogsWebSocketUrl } from "utility/websocketUrls";

const ContainerLogs = ({containerId}) => {
    const dispatch = useDispatch();
    const { previewLogs } = useSelector((state) => state.projectsReducer)
    const { user } = useSelector((state) => state.authReducer)

    useEffect(() => {
        const socketUrl = getContainerLogsWebSocketUrl(containerId, user.uid)
        if (!socketUrl)
          return
        const socket = new WebSocket(socketUrl);
        socket.onopen = () => {
          dispatch(actions.reappendContainerLogs())
        }
        socket.onmessage = (event) => {
          dispatch(actions.appendContainerLogs(event.data))
        };
        return () => {
          // Close the WebSocket connection when the component is unmounted
          if (socket.readyState === WebSocket.OPEN) {
            socket.close();
          }
        };
      }, [dispatch,containerId, user.uid]);

    return (
      <div className="container_logs_text">
        <div>{previewLogs}</div>
        {!previewLogs && containerId && "Loading..."}
      </div>
  );
};

export default ContainerLogs;
