import { WorkflowActionTypes } from './actionType';
import * as API from '../../../api/WorkflowAPI';

export const getWorkflows = (params) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_WORKFLOWS,
  payload: API.getWorkflows(params)
})

export const getWorkflowData = ({body}) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_WORKFLOWDATA,
  payload: API.getWorkflowData(body)
})

export const getWorkflow = (id) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_WORKFLOW,
  payload: API.getWorkflow(id)
})

export const getVisualWorkflow = (id) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_VISUAL_WORKFLOW,
  payload: API.getVisualWorkflow(id)
})

export const markWorkflow = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.MARK_WORKFLOW,
  payload: API.markWorkflow(body, id)
})

export const pauseWorkflow = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.PAUSE_WORKFLOW,
  payload: API.pauseWorkflow(body, id)
})

export const updateWorkflow = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.UPDATE_WORKFLOW,
  payload: API.updateWorkflow(body, id)
})

export const runWorkflow = ({body,id}) => dispatch => dispatch({
  type: WorkflowActionTypes.RUN_WORKFLOW,
  payload: API.runWorkflow(body, id)
})

export const createWorkflow = ({body}) => dispatch => dispatch({
  type: WorkflowActionTypes.CREATE_WORKFLOW,
  payload: API.createWorkflow(body)
})

export const publishWorkflow = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.PUBLISH_WORKFLOW,
  payload: API.publishWorkflow(body, id)
})

export const publishVisualWorkflow = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.PUBLISH_VISUAL_WORKFLOW,
  payload: API.publishVisualWorkflow(body, id)
})

export const getWorkflowRuns = (id) => dispatch => dispatch({
  type: WorkflowActionTypes.GET_WORKFLOW_RUNS,
  payload: API.getWorkflowRuns(id)
})

export const getWorkflowTaskInstances = (id, runId) => dispatch => dispatch({
  type: WorkflowActionTypes.GET_WORKFLOW_TASK_INSTANCES,
  payload: API.getWorkflowTaskInstances(id, runId)
})

export const getWorkflowTaskInstanceLogs = (id, runId, taskId, tryNumber) => dispatch => dispatch({
  type: WorkflowActionTypes.GET_WORKFLOW_TASK_INSTANCE_LOGS,
  payload: API.getWorkflowTaskInstanceLogs(id, runId, taskId, tryNumber)
})

export const getProjectWorkflows = (params) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_PROJECT_WORKFLOWS,
  payload: API.getProjectWorkflows(params)
})

export const getSharedProjectWorkflows = (params) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_SHARED_PROJECT_WORKFLOWS,
  payload: API.getSharedProjectWorkflows(params)
})

export const deleteWorkflow =(id) => (dispatch) => dispatch({
  type: WorkflowActionTypes.DELETE_WORKFLOW,
  payload: API.deleteWorkflow(id)
})

export const removeWorkflow = (id) => ({
  type: WorkflowActionTypes.REMOVE_WORKFLOW,
  payload: id
})

export const clearTask = ({body, id}) => (dispatch) => dispatch({
  type: WorkflowActionTypes.CLEAR_TASK,
  payload: API.clearTask(body, id)
})

export const getLatestTasks = (id) => (dispatch) => dispatch({
  type: WorkflowActionTypes.GET_LATEST_TASKS,
  payload: API.getLatestTask(id)
})

export const createWorkflowShare = (body) => dispatch => dispatch({
  type: WorkflowActionTypes.CREATE_WORKFLOW_SHARE,
  payload: API.createWorkflowShare(body).then(res => ({...res, ...body}))
})

export const getWorkflowShares = (params) => (dispatch) => {
  dispatch({
    type: WorkflowActionTypes.GET_WORKFLOW_SHARES,
    payload: API.getWorkflowShares(params)
  });
}

export const updateSchedule = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.UPDATE_SCHEDULE,
  payload: API.updateSchedule(body, id)
})

export const saveWorkflow = ({body, id}) => dispatch => dispatch({
  type: WorkflowActionTypes.SAVE_WORKFLOW,
  payload: API.saveWorkflow(body, id)
})
