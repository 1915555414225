import {ENTITY_TYPES} from "../../../utility/constants/constants";
import {Button} from "react-bootstrap";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React from "react";
import {Link} from "react-router-dom";
import AddGitAccessModal from "../../ProjectsV2/AddGitAccessModal/AddGitAccessModal";
import CreateProjectModal from "../../ProjectsV2/CreateProjectModal/CreateProjectModal";
import * as commonService from "../../../utility/CommonService";
import * as projectsActions from "../../../redux/actions/ProjectsActions";
import {useDispatch} from "react-redux";
import ImportProjectModal from "../../ProjectsV2/ImportProjectModal/ImportProjectModal";
import { ProjectSelectModal } from "components/Workflows/WorkflowList/ProjectSelectModal";
import CreateWorkflowModal from "components/Workflows/WorkflowDetails/CreateWorkflowModal";

const CreateAndImportButtons = ({
                                  entityType,
                                  showImportModal,
                                  setShowImportModal,
                                  showCreateModal,
                                  setShowCreateModal,
                                  showCreateWorkflowModal,
                                  setShowCreateWorkflowModal,
                                  showProjectSelectModal,
                                  setShowProjectSelectModal,
                                  newProject,
                                  setNewProject,
                                  filterParams,
                                  user,
                                }) => {

  const dispatch = useDispatch();
  const validateName = (name) => {
    const regEx = /^[a-zA-Z0-9_.\- ]+$/;
    return regEx.test(name);
  }
  const createProject = () => {
    if (!validateName(newProject.name)) {
      commonService.forError("Project name can only contain letters, numbers, underscores, hyphens, and periods", "Error")
      return
    }
    dispatch(projectsActions.createProject({body: newProject})).then(() => {
      setShowCreateModal(false);
      setShowImportModal(false);
      dispatch(projectsActions.getProjects(filterParams));
    });
  };

  return (
    <>
      <div>
        {
          entityType === ENTITY_TYPES.PROJECT_V2 &&
          (
            user.git_token ? (
              <>
                <Button
                  onClick={() => setShowImportModal(true)}
                  className="btn"
                >
                  <ImportExportIcon /> import project
                </Button>
                <Button
                  onClick={() => setShowCreateModal(true)}
                  className="mr-2 btn"
                >
                  <AddCircleOutlineIcon /> create project
                </Button>
              </>
            ) : (
              <Button
                className="float-right btn"
                data-toggle="modal"
                data-target="#gitModal"
              >
                <AddCircleOutlineIcon /> create project
              </Button>
            )
          )
        }
        {
          entityType === ENTITY_TYPES.DATASET &&
          <Link to="datasets/new" className="float-right btn">
            Import dataset
          </Link>
        }
        {
          entityType === ENTITY_TYPES.WORKFLOW &&
          (
            <Button
              className="float-right btn"
              onClick={() => setShowProjectSelectModal(true)}
            >
              <AddCircleOutlineIcon /> create new
            </Button>
          )
        }
      </div>
      {/*All Projects v2 Modals*/}
      <AddGitAccessModal />
      <CreateProjectModal showCreateModal={showCreateModal}
                          setShowCreateModal={setShowCreateModal}
                          newProject={newProject}
                          setNewProject={setNewProject}
                          createProject={createProject} />
      <ImportProjectModal showImportModal={showImportModal}
                          setShowImportModal={setShowImportModal}
                          newProject={newProject}
                          setNewProject={setNewProject}
                          createProject={createProject} />
      <ProjectSelectModal show={showProjectSelectModal}
                          setShowCreateWorkflowModal={setShowCreateWorkflowModal}
                          setShowProjectSelectModal={setShowProjectSelectModal}
                          filterParams={filterParams}/>
      <CreateWorkflowModal  show={showCreateWorkflowModal}
                            setShowCreateWorkflowModal={setShowCreateWorkflowModal}
                            setShowWorkflowPublishModal={setShowProjectSelectModal} />
    </>
  )
}

export default CreateAndImportButtons