export const WorkflowActionTypes = {

  GET_WORKFLOWS: "GET_WORKFLOWS",
  GET_WORKFLOWS_PENDING: "GET_WORKFLOWS_PENDING",
  GET_WORKFLOWS_FULFILLED: "GET_WORKFLOWS_FULFILLED",

  GET_WORKFLOWDATA: "GET_WORKFLOWDATA",
  GET_WORKFLOWDATA_PENDING: "GET_WORKFLOWDATA_PENDING",
  GET_WORKFLOWDATA_FULFILLED: "GET_WORKFLOWDATA_FULFILLED",

  GET_WORKFLOW: "GET_WORKFLOW",
  GET_WORKFLOW_PENDING: "GET_WORKFLOW_PENDING",
  GET_WORKFLOW_FULFILLED: "GET_WORKFLOW_FULFILLED",

  GET_VISUAL_WORKFLOW: "GET_VISUAL_WORKFLOW",
  GET_VISUAL_WORKFLOW_PENDING: "GET_VISUAL_WORKFLOW_PENDING",
  GET_VISUAL_WORKFLOW_FULFILLED: "GET_VISUAL_WORKFLOW_FULFILLED",

  UPDATE_WORKFLOW: "UPDATE_WORKFLOW",
  UPDATE_WORKFLOW_PENDING: "UPDATE_WORKFLOW_PENDING",
  UPDATE_WORKFLOW_FULFILLED: "UPDATE_WORKFLOW_FULFILLED",

  PAUSE_WORKFLOW: "PAUSE_WORKFLOW",
  PAUSE_WORKFLOW_PENDING: "PAUSE_WORKFLOW_PENDING",
  PAUSE_WORKFLOW_FULFILLED: "PAUSE_WORKFLOW_FULFILLED",

  MARK_WORKFLOW: "MARK_WORKFLOW",
  MARK_WORKFLOW_PENDING: "MARK_WORKFLOW_PENDING",
  MARK_WORKFLOW_FULFILLED: "MARK_WORKFLOW_FULFILLED",

  RUN_WORKFLOW: "RUN_WORKFLOW",
  RUN_WORKFLOW_PENDING: "RUN_WORKFLOW_PENDING",
  RUN_WORKFLOW_FULFILLED: "RUN_WORKFLOW_FULFILLED",

  CREATE_WORKFLOW: "CREATE_WORKFLOW",
  CREATE_WORKFLOW_PENDNG: "CREATE_WORKFLOW_PENDNG",
  CREATE_WORKFLOW_FULFILLED: "CREATE_WORKFLOW_FULFILLED",

  CREATE_VISUAL_WORKFLOW: "CREATE_VISUAL_WORKFLOW",
  CREATE_VISUAL_WORKFLOW_PENDNG: "CREATE_VISUAL_WORKFLOW_PENDNG",
  CREATE_VISUAL_WORKFLOW_FULFILLED: "CREATE_VISUAL_WORKFLOW_FULFILLED",

  PUBLISH_WORKFLOW: "PUBLISH_WORKFLOW",
  PUBLISH_WORKFLOW_PENDING: "PUBLISH_WORKFLOW_PENDING",
  PUBLISH_WORKFLOW_FULFILLED: "PUBLISH_WORKFLOW_FULFILLED",

  GET_WORKFLOW_RUNS: "GET_WORKFLOW_RUNS",
  GET_WORKFLOW_RUNS_PENDING: "GET_WORKFLOW_RUNS_PENDING",
  GET_WORKFLOW_RUNS_FULFILLED: "GET_WORKFLOW_RUNS_FULFILLED",

  GET_WORKFLOW_TASK_INSTANCES: "GET_WORKFLOW_TASK_INSTANCES",
  GET_WORKFLOW_TASK_INSTANCES_PENDING: "GET_WORKFLOW_TASK_INSTANCES_PENDING",
  GET_WORKFLOW_TASK_INSTANCES_FULFILLED: "GET_WORKFLOW_TASK_INSTANCES_FULFILLED",

  GET_WORKFLOW_TASK_INSTANCE_LOGS: "GET_WORKFLOW_TASK_INSTANCE_LOGS",
  GET_WORKFLOW_TASK_INSTANCE_LOGS_PENDING: "GET_WORKFLOW_TASK_INSTANCE_LOGS_PENDING",
  GET_WORKFLOW_TASK_INSTANCE_LOGS_FULFILLED: "GET_WORKFLOW_TASK_INSTANCE_LOGS_FULFILLED",

  GET_PROJECT_WORKFLOWS: "GET_PROJECT_WORKFLOWS",
  GET_PROJECT_WORKFLOWS_PENDING: "GET_PROJECT_WORKFLOWS_PENDING",
  GET_PROJECT_WORKFLOWS_FULFILLED: "GET_PROJECT_WORKFLOWS_FULFILLED",

  GET_SHARED_PROJECT_WORKFLOWS: "GET_SHARED_PROJECT_WORKFLOWS",
  GET_SHARED_PROJECT_WORKFLOWS_PENDING: "GET_SHARED_PROJECT_WORKFLOWS_PENDING",
  GET_SHARED_PROJECT_WORKFLOWS_FULFILLED: "GET_SHARED_PROJECT_WORKFLOWS_FULFILLED",

  DELETE_WORKFLOW: "DELETE_WORKFLOW",
  DELETE_WORKFLOW_PENDING: "DELETE_WORKFLOW_PENDING",
  DELETE_WORKFLOW_FULFILLED: "DELETE_WORKFLOW_FULFILLED",

  GET_IMPORT_ERRORS: "GET_IMPORT_ERRORS",
  GET_IMPORT_ERRORS_PENDING: "GET_IMPORT_ERRORS_PENDING",
  GET_IMPORT_ERRORS_FULFILLED: "GET_IMPORT_ERRORS_FULFILLED",

  CLEAR_TASK: "CLEAR_TASK",
  CLEAR_TASK_PENDING: "CLEAR_TASK_PENDING",
  CLEAR_TASK_FULFILLED: "CLEAR_TASK_FULFILLED",

  GET_LATEST_TASKS: "GET_LATEST_TASKS",
  GET_LATEST_TASKS_PENDING: "GET_LATEST_TASKS_PENDING",
  GET_LATEST_TASKS_FULFUILLED: "GET_LATEST_TASKS_FULFILLED",

  CREATE_WORKFLOW_SHARE: "CREATE_WORKFLOW_SHARE",
  CREATE_WORKFLOW_SHARE_PENDNG: "CREATE_WORKFLOW_SHARE_PENDNG",
  CREATE_WORKFLOW_SHARE_FULFILLED: "CREATE_WORKFLOW_SHARE_FULFILLED",

  GET_WORKFLOW_SHARES: "GET_WORKFLOW_SHARES",
  GET_WORKFLOW_SHARES_PENDNG: "GET_WORKFLOW_SHARES_PENDNG",
  GET_WORKFLOW_SHARES_FULFILLED: "GET_WORKFLOW_SHARES_FULFILLED",

  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_PENDING: "UPDATE_SCHEDULE_PENDING",
  UPDATE_SCHEDULE_FULFILLED: "UPDATE_SCHEDULE_FULFILLED",

  SAVE_WORKFLOW: "SAVE_WORKFLOW",
  SAVE_WORKFLOW_PENDNG: "SAVE_WORKFLOW_PENDNG",
  SAVE_WORKFLOW_FULFILLED: "SAVE_WORKFLOW_FULFILLED",

  PUBLISH_VISUAL_WORKFLOW: "PUBLISH_VISUAL_WORKFLOW",
  PUBLISH_VISUAL_WORKFLOW_PENDING: "PUBLISH_VISUAL_WORKFLOW_PENDING",
  PUBLISH_VISUAL_WORKFLOW_FULFILLED: "PUBLISH_VISUAL_WORKFLOW_FULFILLED",

  REMOVE_WORKFLOW: 'REMOVE_WORKFLOW',

};