import React, { useContext, useState } from "react";
import * as commonService from "utility/CommonService.js";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import moment from "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DEFAULT_DATETIME_OPTION, FAILURE_COLOR,
  SUCCESS_COLOR
} from "../../../utility/constants/constants";
import {
  clearUsers,
  deleteUser
} from "../../../redux/actions/UserActions/Action";
import style from "./users.module.css";
import { Check, Close } from "@mui/icons-material";
import EditUserModal from "../../../screens/Users/EditUserModal";
import {
  getActiveUserCounts
} from "../../../redux/actions/OrganizationActions/Action";
import { getOrganization } from "../../../redux/actions/OrganizationActions/Action";
import { UserPaginationContext } from "../../Admin/AdminNavContainer";

const UsersTable = ({
                      users,
                      userCount,
                      organization
                    }) => {
  const dispatch = useDispatch();
  const { userPagination, handleUserPagination } = useContext(UserPaginationContext);
  const { user } = useSelector((state) => state.authReducer);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpen = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  }


  const handleDelete = (uid) => {
    swal({
      title: "",
      text: "Are you sure?",
      className: "logout-alert-modal",
      buttons: true,
    }).then(async (result) => {
      if (result) {
        const res = await dispatch(deleteUser(uid))
        if (res.value) {
          await dispatch(
            clearUsers()
          );
          commonService.forSuccess("User Deleted", "Success");
          handleUserPagination({
            page: 1,
            limit: 10,
          })
          await dispatch(
            getOrganization({ id: organization })
          )
          await dispatch(getActiveUserCounts())
        }
      }
    })
  };

  return (
    <>
      <div className="users_table">
        <InfiniteScroll
          dataLength={users.length}
          next={() => {
            handleUserPagination({ ...userPagination, page: userPagination.page + 1 })
          }}
          hasMore={users.length < parseInt(userCount)}
        >
          <table className={style.Table}>
            <thead className={style.Table_head}>
            <tr className={style.UserTable_header}>
              <th
                className={`${style.Table_cell} ${style.pl_20} ${style.header_cell}`}
              >
                Name
              </th>
              <th
                className={`${style.Table_cell} ${style.header_cell}`}
              >
                Email
              </th>
              <th
                className={`${style.Table_cell} ${style.header_cell}`}
              >
                Role
              </th>
              <th
                className={`${style.Table_cell} ${style.header_cell} ${style.center}`}
              >
                2FA Enabled
              </th>
              <th
                className={`${style.Table_cell} ${style.header_cell}`}
              >
                Created At
              </th>
              <th
                className={`${style.Table_cell}`}
              ></th>
            </tr>
            </thead>
            <tbody className={style.Table_body}>
            {users && users?.map((userEntry, index) => (
              <tr
                key={index}
                className={`${style.db_ListViewItem} ${style.db_ListViewItem_hasLink}`}
              >
                <td className={`${style.Table_cell_I_inn} ${style.pl_20}`}>
                  {userEntry.first_name} {userEntry.last_name}
                </td>
                <td className={style.Table_cell_I_inn}>
                  {userEntry.email}
                </td>
                <td className={style.Table_cell_I_inn}>
                  {userEntry.role}
                </td>
                <td className={`${style.Table_cell_I_inn} ${style.center}`}>
                  <span>{userEntry.enabled_2fa ?
                    <Check sx={{ color: SUCCESS_COLOR }} /> :
                    <Close sx={{ color: FAILURE_COLOR }} />}</span>
                </td>
                <td className={`${style.Table_cell_I_inn} ${style.pr_20}`}>
                <span>
                  {moment(userEntry.created_at).format((user.date_format ? user.date_format : DEFAULT_DATETIME_OPTION), "HH:mm A")}
                </span>
                </td>
                <td className={`${style.Table_cell_I_inn} ${style.pr_20}`}>
                  <button
                    className={` ${style.btn_transperent} ${style.ldsToolTips}`}>
                    <div className="show__tool mr-2"
                         onClick={() => handleOpen(userEntry)}>
                      <i
                        className={`fa fa-edit ${style.color_primary}`}
                      ></i>{" "}
                      <span className="show_pin_rep">Edit</span>
                    </div>
                  </button>
                  <button
                    className={` ${style.btn_transperent} ${style.ldsToolTips}`}
                    onClick={() => handleDelete(userEntry.uid)}>
                    {" "}
                    <div className="show__tool mr-2">
                      <i className="fa fa-trash text-danger"></i>
                      <span className="show_pin_rep">Delete</span>
                    </div>
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </InfiniteScroll>
        <div className={style.Divider_light_bottom}>
          <div className={style.Box_root}>
            <div className={style.left_Side}>
              <div className={style.left_Side}>
              <span className={style.result_All}>
                <strong>{userCount}</strong> results
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditUserModal showModal={showEditModal} setShowModal={setShowEditModal}
                     user={selectedUser} selectedOrg={organization}
                     handleUserPagination={handleUserPagination}></EditUserModal>
    </>
  );
};
export default UsersTable;

