import React , {useState} from "react";
import { useDispatch } from "react-redux";
import { EditEntityModal } from "components/shared/Modals/EditEntityModal";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import * as commonService from "utility/CommonService.js"
import * as actions from "../../../redux/actions/WorkflowActions"
import {Button} from 'react-bootstrap'
import { forError, forSuccess } from "utility/CommonService.js";
import EditScheduleModal from "./EditScheduleModal";
import { ACCESS_LEVEL, FLIGHTING_CONSTANTS, routes } from "utility/constants/constants";
import Tooltip from '@mui/material/Tooltip';
import { StyledMenu, StyledMenuItem } from "components/shared/header/components/StyledVisualWorkflowComponents";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { CalendarMonth } from "@mui/icons-material";
import { DeleteWorkflowModal } from "components/shared/Modals/DeleteWorkflowModal";
import { setPermissionTooltip, setPermissionOpacity } from "utility/utility";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  updateWorkflow
} from "redux/actions/WorkflowActions";
import { ENTITY_TYPES } from "utility/constants/constants";

const PauseSwitch = styled(Switch)(() => ({
  padding: 4,
  '& .MuiSwitch-track': {
    borderRadius: "20px",
    backgroundColor: "#E5E4E2",
    opacity: "1 !important",
  },
  '& .MuiSwitch-thumb': {
    color: "white",
    width: "20px",
    height: "20px",
  },
}));

export const WorkflowHeader = ({ workflow }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showEditScheduleModal, setShowEditScheduleModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const history = useHistory();
  const menuOpen = Boolean(menuAnchor);
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = (e) => {
    if (e) e.stopPropagation()
    setMenuAnchor(null);
    dispatch(actions.getWorkflow(workflow.id));
  };

  const dispatch = useDispatch();

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const openScheduleModal = () => {
    setShowEditScheduleModal(true);
  }

  const triggerDag = async () => {
    dispatch(actions.runWorkflow({
      body: {
        is_paused: workflow.dag_info?.is_paused
      },
      id: workflow.id
    })).then((res) => {
      if (res.value && res.value.success) {
        forSuccess("Workflow run successful!", "Success");
      } else {
        forError("Workflow run failed", "Error");
      }
    });
  }

  const handleChange = async (event, workflow)  => {
    let id = workflow.id
    workflow.dag_info.is_paused = !event.target.checked;
    dispatch(actions.pauseWorkflow(
      {
        body: {
          paused: event.target.checked ? false : true
        },
        id
      }
    )).then((res) => {
      if (res.value && res.value.success) {
        dispatch(actions.getWorkflows());
        commonService.forSuccess("Workflow update successful!", "Success")
      } else {
        commonService.forError("Workflow update failed", "Error")
      }
    })
  };

  return (
    <div className="container">
      <div className="workflow_details_header">
        <div className="top_row">
          <div className="workflow_breadcrumb">
          <BreadCrumb
              currentLoc={workflow && workflow.name}
              previousLoc={FLIGHTING_CONSTANTS.WORKFLOWS}
              goBack={() => history.push(routes.WORKFLOWS)}
            />
          </div>
          <div className="top_row_right">
            {workflow && <Tooltip
              title={
                  setPermissionTooltip(ACCESS_LEVEL.EXECUTE, workflow?.access_level, "")
              }
              placement="top"
            >
              <span>
                <Button
                  className="btn_small"
                  onClick={() => triggerDag()}
                  style={{
                    opacity:setPermissionOpacity(ACCESS_LEVEL.EXECUTE, workflow?.access_level),
                  }}
                  disabled={workflow?.access_level === ACCESS_LEVEL.VIEW}
                >
                  <div className="trigger_dag">
                    <div className="circle_style">
                      <div className="arrow_style"></div>
                    </div>
                    Trigger Dag
                  </div>
                </Button>
              </span>
            </Tooltip>}
            {workflow && <Tooltip
              title={
                setPermissionTooltip(ACCESS_LEVEL.EXECUTE, workflow?.access_level, "")
              }
              placement="top"
            >
              <span>
                <Button
                  onClick={() => openScheduleModal()}
                  className="btn_small"
                  style={{
                    opacity:setPermissionOpacity(ACCESS_LEVEL.EXECUTE, workflow?.access_level),
                  }}
                  disabled={workflow?.access_level !== ACCESS_LEVEL.EDIT}
                >
                  <div className="organize_btn">
                    <CalendarMonth height="25px"></CalendarMonth>
                    Schedule
                  </div>
                </Button>
              </span>
            </Tooltip>}
            <span>
              <Button
                className="btn_small"
                id="white_btn"
                onClick={(event) => handleMenuClick(event)}
                disabled={false}
              >
                ...
              </Button>
            </span>
          </div>
        </div>
        <div
          className="active-toggle"
          style={{
            paddingLeft: "40px",
            opacity: setPermissionOpacity(ACCESS_LEVEL.EXECUTE, workflow?.access_level),
          }}
        >
          <Tooltip
            title={
              setPermissionTooltip(ACCESS_LEVEL.EXECUTE, workflow?.access_level, "")
            }
            placement="top"
          >
            <div className="active-tooltip">
              <h5 className="active-text">Active</h5>
              <PauseSwitch
                color="success"
                checked={!workflow?.dag_info?.is_paused}
                onChange={(e) => {
                  handleChange(e, workflow);
                }}
                name="workflowRunState"
                disabled={workflow?.access_level === ACCESS_LEVEL.VIEW}
              />
            </div>
          </Tooltip>
        </div>
        <EditScheduleModal
          show={showEditScheduleModal}
          setShowEditScheduleModal={setShowEditScheduleModal}
          id={workflow?.id}
          projectId={workflow?.project_id}
          accessLevel={workflow?.access_level}
        />
        <div className="show_tool">
          <StyledMenu
            id="long-menu-button"
            disableScrollLock={true}
            anchorEl={menuAnchor}
            open={menuOpen}
            onClose={handleMenuClose}
          >
            <StyledMenuItem
              style={{
                opacity: setPermissionOpacity(ACCESS_LEVEL.EXECUTE, workflow?.access_level),
              }}
              disabled={workflow?.access_level !== ACCESS_LEVEL.EDIT}
              onClick={(e) => {
                setMenuAnchor(null);
                openDeleteModal();
              }}
            >
              <ListItemIcon>
                <div className="entity_menu_item">
                  <DeleteIcon fontSize="small" />
                  <p> Delete</p>
                </div>
              </ListItemIcon>
            </StyledMenuItem>
            <StyledMenuItem
              dense={true}
              onKeyDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowEditModal(true);
                handleMenuClose();
                e.stopPropagation();
              }}
            >
              <ListItemIcon className="edit">
                <div className="entity_menu_item">
                  <img
                    src={require("assets/custom-styles/images/thumbnails/edit_icn.jpg")}
                    alt="Edit Entity"
                    className="cursor-pointer optionsIcon"
                  />
                  <p>Edit</p>
                </div>
              </ListItemIcon>
            </StyledMenuItem>
          </StyledMenu>
          <DeleteWorkflowModal
            entity={workflow}
            handleMenuClose={closeDeleteModal}
            showModal={deleteModalOpen}
            setShowModal={openDeleteModal}
          />
          <EditScheduleModal
            show={showEditScheduleModal}
            setShowEditScheduleModal={setShowEditScheduleModal}
            id={workflow?.id}
            projectId={workflow?.project_id}
            accessLevel={workflow?.access_level}
          />
          <EditEntityModal
            entity={workflow}
            entityType={ENTITY_TYPES.WORKFLOW}
            updateEntity={updateWorkflow}
            handleMenuClose={handleMenuClose}
            showModal={showEditModal}
            setShowModal={setShowEditModal}
          />
        </div>
      </div>
    </div>
  );
};
