import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import ImportError from './ImportError';
import { Skeleton } from "@mui/material";


const StatusCell = ({ workflow }) => {
  const statusClass = `status-cell status-${workflow?.status}`;

  return (
    <TableCell className={`${statusClass} custom-table-cell`}>
      {workflow ? (
        <div className="status-content">
          {workflow?.status}
          {workflow?.status === "broken" && <ImportError workflow={workflow}/>}
        </div>
      ) : (
        <Skeleton size={30}></Skeleton>
      )}
    </TableCell>
  );
};

export default StatusCell;
