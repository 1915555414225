import React, { useState } from "react";

import ClusteringTemplate from "../InsightList/Templates/ClusteringTemplate/ClusteringTemplate";
import SliceAndDiceTemplate from "../InsightList/Templates/SliceAndDiceTemplate/SliceAndDiceTemplate";
import { ACCESS_LEVEL, APP_TEMPLATES, FLIGHTING_CONSTANTS, routes } from "utility/constants/constants";
import CampaignApp from "../InsightList/Templates/CampaignApp/campaignApp";
import SlidingWindow from "./AppDevTools/SlidingWindow";
import BreadCrumb from "components/shared/Navigation/BreadCrumb";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const App = ({ application, iframe, localURL }) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const hasAccess = ACCESS_LEVEL.ALL_EXECUTE.includes(application.access_level);
  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };
  const history = useHistory();

  return (
    <div className="lds_right_box_wrapper lds_right_po_box_wrapper notebook-open-wrapper">
      <div className="app_details_header">
        <BreadCrumb
          currentLoc={application.name}
          previousLoc={`${FLIGHTING_CONSTANTS.INSIGHTS} List`}
          goBack={() => history.push(routes.INSIGHTS_LIST)}
        />
        {hasAccess && application.container_name && (
          <button className="btn_small" onClick={togglePanel}>
            Logs
          </button>
        )}
      </div>
      {hasAccess && application.container_name &&  (
        <SlidingWindow
          isPanelOpen={isPanelOpen}
          onBackdropClicked={togglePanel}
        />
      )}
      <div className={`iframe-container2 ${localURL ? "show" : "fade"}`}>
        {application.app_type === APP_TEMPLATES.CLUSTER ? (
          <ClusteringTemplate application={application}></ClusteringTemplate>
        ) : application.app_type === APP_TEMPLATES.SLICEANDDICE ? (
          <SliceAndDiceTemplate
            application={application}
          ></SliceAndDiceTemplate>
        ) : application.app_type === APP_TEMPLATES.CAMPAIGN ? (
          <CampaignApp application={application}></CampaignApp> // Leave commented out for campaign app testing [ENG-1597]
        ) : (
          <iframe
            src={iframe}
            frameBorder="0"
            title="notebookOpenCommit"
            style={{ height: "94vh" }}
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default App;
