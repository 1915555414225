import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../redux/actions/WorkflowActions";
import {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  getConnectedEdges,
} from "reactflow";
import VisualWorkflowsHeader from "./VisualWorkflowsHeader";
import VisualWorkflowConfig from "./VisualWorkflowConfig";
import WorkflowDesigner from "./WorkflowDesigner";

const VisualWorkflows = ({ id }) => {
  const dispatch = useDispatch();
  const { visualWorkflow } = useSelector((state) => state.workflowReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [taskSelected, setTaskSelected] = useState(false);
  const handleTaskSelect = (val) => {
    setTaskSelected(val);
  }
  const initialNodes = [
    {
      id: '0',
      type: 'taskNode',
      position: { x: 0, y: 50 },
      data: { label: '' , taskType: [], taskData: [], taskFunction: handleTaskSelect}
    },
  ];

  const [selectedNode, setSelectedNode] = useState(initialNodes[0]);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const handleNodeClick = (newNode) => {
    setSelectedNode(newNode);
  };

  useEffect(() => {
    if (selectedNode && selectedNode.data.taskType.length === 0) {
      handleTaskSelect(false)
    }
  }, [selectedNode])

  useEffect(() => {
    const fetchData = async () => {
      const visualWorkflowResponse = await dispatch(actions.getVisualWorkflow(id));
      if (visualWorkflowResponse.action.payload && visualWorkflowResponse.action.payload.success) {
        if (visualWorkflowResponse.value.workflow.nodes) {
          if (visualWorkflowResponse.value.workflow.nodes.length === 0) {
            setNodes([{
              id: '0',
              type: 'taskNode',
              position: { x: 0, y: 50 },
              data: { label: '' , taskType: [], taskData: [], taskFunction: handleTaskSelect}
            }])
          } else {
            const nodes = visualWorkflowResponse.value.workflow.nodes.map(node => ({
              ...node,
              selected: false
            }));
            setNodes(nodes)
            setEdges(visualWorkflowResponse.value.workflow.edges);
          }
        }
      }
    };
    fetchData();

  }, [dispatch, setNodes, setEdges, id])

  return (
    <div className="visual_workflow">
      <VisualWorkflowsHeader
        workflow={visualWorkflow}
        user={user}
        nodes={nodes}
        edges={edges}
      ></VisualWorkflowsHeader>
      <div className="workflow_designer_container">
        <div className="workflow_designer_wrapper">
          <ReactFlowProvider>
            <WorkflowDesigner
              handleTaskSelect={handleTaskSelect}
              handleNodeClick={handleNodeClick}
              nodes={nodes}
              edges={edges}
              setNodes={setNodes}
              setEdges={setEdges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
            />
          </ReactFlowProvider>
        </div>
        <div className="visual_workflow_config_wrapper">
          <VisualWorkflowConfig
            workflow={visualWorkflow}
            selectedNode={selectedNode}
            setNodes={setNodes}
            setEdges={setEdges}
            edges={edges}
            taskSelected={taskSelected}
            id={visualWorkflow?.project_id}
            getConnectedEdges={getConnectedEdges}
          />
        </div>
      </div>
    </div>
  );
};

export default VisualWorkflows;