import axios from 'config';

import { AuthActionTypes } from './actionType';
import * as API from '../../../api/authAPI';
import storage from '../../../utility/storage';
import storeAndPersistor from "../../store/store";

export const login = (credentials) => dispatch => {
    return dispatch({
        type: AuthActionTypes.LOGIN,
        payload: API.login(credentials).then(res => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${res.token}`;
            return res
        })
})
};

export const signup = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.SIGNUP,
    payload: API.signup(credentials)
        .then(response => {
            if (response.token) axios.defaults.headers.common["Authorization"] = `Bearer ${response.token}`;
            return response;
        })
});

export const forgotPassword = (credentials) => dispatch => dispatch({
        type: AuthActionTypes.FORGOT_PASSWORD,
        payload: API.forgotPassword(credentials)
    });

export const resetPassword = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.RESET_PASSWORD,
    payload: API.resetPassword(credentials)
        .then(response => {
            return response
        })
});

export const changePassword = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.RESET_PASSWORD,
    payload: API.changePassword(credentials)
        .then(response => {
            return response
        })
});
export const refreshToken = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.REFRESH_TOKEN,
    payload: API.refreshToken(credentials)
        .then(response => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${response.token}`;
            return response
        })
});

export const authorizeUser = (user_profile) => {
    console.log("authorize:" + user_profile.access_token);
    console.log("user:" + user_profile);

    storage.set('token', user_profile.access_token);
    storage.set('refresh_token', user_profile.refresh_token);
    storage.set('user', user_profile);


    const token = user_profile.access_token;
    const refresh_token = user_profile.refresh_token;

    return {
        type: AuthActionTypes.AUTHORIZE,
        payload: {
            token,
            user_profile,
            refresh_token
        }
    }
};

export const logout = () => {
    storeAndPersistor.persistor.purge();
    storage.remove('token');
    storage.remove('user');
    storage.remove('refresh_token');
    storage.remove('step');
    // setTimeout(() => window.location.replace(routes.HOME), 500);
    return {
        type: AuthActionTypes.LOGOUT,
    }
};

export const updateUserProfile = ({body, id}) => dispatch => dispatch({
    type: AuthActionTypes.UPDATE_USER,
    payload: API.updateUser(body, id)
});
export const getImageAccess = (params) => dispatch => dispatch({
    type: AuthActionTypes.GET_IMAGE_ACCESS,
    payload: API.getImageAccess(params)
});

export const gitDetailsAccess = ({body}) => dispatch => dispatch({
    type: AuthActionTypes.GIT_DETAILS,
    payload: API.gitDetailsAccess(body)
});

export const enable2FA = ({body}) => dispatch => dispatch({
    type: AuthActionTypes.ENABLE_2FA,
    payload: API.enable2FA(body)
});

export const verify2FA = ({body}) => dispatch => dispatch({
    type: AuthActionTypes.VERIFY_2FA,
    payload: API.verify2FA(body)
});
