import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { forError, forSuccess } from "utility/CommonService.js";
import { useHistory } from "react-router-dom";
import { deleteWorkflow, removeWorkflow} from "../../../redux/actions/WorkflowActions";

export const DeleteWorkflowModal = ({entity, handleMenuClose, showModal, setShowModal}) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const handleClose = () => {
    setShowModal(false);
    handleMenuClose();
  };

  const dispatchDeleteWorkflow = async (e) => {
    e.preventDefault();
    dispatch(deleteWorkflow(entity?.id)).then((res) => {
      if (res.value && res.value.success) {
        forSuccess("Workflow delete successful!", "Success");
        dispatch(removeWorkflow(entity?.id))
        if (history.state !== "/workflows") {
          history.push("/workflows");
        }
      } else {
        forError("Workflow delete failed", "Error");
      }
      handleClose();
    });
  };

  return (
    <>
      <Modal
        id="edit_modal"
        show={showModal}
        onHide={handleClose}
        animation={false}
        className="edit-app-modal"
      >
        <Modal.Header closeButton><h4>Delete Workflow</h4></Modal.Header>
        <form data-toggle="validator" onSubmit={dispatchDeleteWorkflow}>
          <Modal.Body>
            <div className="col-md-12">
              <div className="publish_modal">
                <p>Name</p>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={entity?.name}
                  disabled={true}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" value="Submit" className="btn float-right">
              Delete Workflow
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
