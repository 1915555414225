import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import EventIcon from "@mui/icons-material/Event";
import { Select } from "components/shared/ui-components/form-components";
import { useDispatch, useSelector } from "react-redux";
import { TASK_COLORS, TASK_BORDER } from "utility/constants/constants";
import Tooltip from "@mui/material/Tooltip";
import * as actions from "../../../redux/actions/WorkflowActions";
import { DEFAULT_DATETIME_OPTION } from "utility/constants/constants";
import moment from "moment-timezone";
import { TASK_STATE } from "utility/constants/constants";

const TaskDisplay = ({
  id,
  handleSelectWorkflowRun,
  handleSelectWorkflowTaskInstance,
  handleSelectWorkflowTaskTryNumber,
  workflowRun,
  workflowTaskInstance,
  workflowTaskInstanceObject,
  workflowTaskTryNumber,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { workflowTaskInstances, latestTasks } = useSelector(
    (state) => state.workflowReducer
  );
  const { user } = useSelector((state) => state.authReducer);
  const [selectedTask, setSelectedTask] = useState({});
  const dispatch = useDispatch();

  const dagRunTimeKeys = Object.keys(latestTasks);
  const handleLogsSubmit = () => {
    dispatch(
      actions.getWorkflowTaskInstanceLogs(
        id,
        workflowRun.value,
        workflowTaskInstance.value,
        workflowTaskTryNumber.value
      )
    );
    handleClose();
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    handleSelectWorkflowRun({ name: moment(task.start_date)
      .tz(user.timezone)
      .format(
        (user.date_format
          ? user.date_format
          : DEFAULT_DATETIME_OPTION) + " h:mm:ss a"
      ), value: task.dag_run_id });
    handleSelectWorkflowTaskInstance({
      name: task.task_id,
      value: task.task_id,
    });
    handleSelectWorkflowTaskTryNumber({
      name: task.try_number,
      value: task.try_number,
    });
    dispatch(
      actions.getWorkflowTaskInstanceLogs(
        id,
        task.dag_run_id,
        task.task_id,
        task.try_number
      )
    );
  };

  const getTaskRowColor = (dagRunTime) => {
    const rowSelected = dagRunTime === selectedTask?.dag_run_id;
    if (rowSelected) {
      return TASK_COLORS.SELECTED;
    } else {
      return TASK_COLORS.CLEAR;
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  function isDagSuccess(dagruntime) {
    const allSuccess = latestTasks[dagruntime].every(
      (task) => task.state === TASK_STATE[0]
    );
    const dagFail = latestTasks[dagruntime].some(
      (task) => task.state === TASK_STATE[1]
    );
    if (allSuccess) {
      return TASK_COLORS.SUCCESS;
    } else if (dagFail) {
      return TASK_COLORS.FAILED;
    } else {
      return TASK_COLORS.DEFAULT;
    }
  }

  function getTaskBorder(task) {
    if (
      selectedTask.task_id === task.task_id &&
      selectedTask.dag_run_id === task.dag_run_id
    ) {
      return TASK_BORDER.SELECTED;
    } else return TASK_BORDER.CLEAR;
  }

  function getTaskColor(taskState) {
    if (taskState === "failed") {
      return TASK_COLORS.FAILED;
    } else if (taskState === "upstream_failed") {
      return TASK_COLORS.UPSTREAM_FAILED;
    } else if (taskState === "success") {
      return TASK_COLORS.SUCCESS;
    } else {
      return TASK_COLORS.DEFAULT;
    }
  }

  // generate list of task try numbers for dropdown menu
  function generateTryNumberList(n) {
    const options = [];
    for (let i = 1; i <= n; i++) {
      options.push({ name: i, id: i });
    }
    return options;
  }

  return (
    <div className="card-container">
      <h4 className="project-card-title">Most Recent Runs</h4>
      <div className="project-card">
        <div className="project-card-header"></div>
        <div className="project-card-content">
          {dagRunTimeKeys.slice(0, 5).map((dagruntime, index) => (
            <div
              key={index}
              className="task-row"
              style={{ backgroundColor: getTaskRowColor(dagruntime) }}
            >
              <div className="dag-date">
                {moment(dagruntime.split("__")[1])
                      .tz(user.timezone)
                      .format("DD-MMM")
                    }
              </div>
              <div
                className="dag-circle"
                style={{ backgroundColor: isDagSuccess(dagruntime) }}
              ></div>
              <div className="task-list">
                {latestTasks[dagruntime].map((task, taskIndex) => (
                  <div key={taskIndex} className="task-div">
                    <Tooltip
                      title={
                        <div>
                          <div>Task ID: {task.task_id}</div>
                          <div>State: {task.state}</div>
                          <div>Duration: {task.duration}</div>
                          <div>Start Date: {task.start_date}</div>
                        </div>
                      }
                    >
                      <div
                        className="task-square"
                        style={{
                          backgroundColor: getTaskColor(task.state),
                          border: getTaskBorder(task),
                        }}
                        onClick={() => handleTaskClick(task)}
                      ></div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="project-card-footer">
          <Button className="view-code-button" onClick={() => openModal()}>
            <EventIcon className="project-card-icon" />
            Select from history
          </Button>
        </div>
      </div>
      <Modal
        id="edit_modal"
        show={showModal}
        onHide={handleClose}
        animation={false}
        className="edit-app-modal"
      >
        <Modal.Header closeButton>
          <h3>Select From History</h3>
        </Modal.Header>
        <form data-toggle="validator" onSubmit={handleLogsSubmit}>
          <Modal.Body>
            <div className="col-md-12">
              <div className="workflow-select">
                <h4>Workflow Run ID</h4>
                <Select
                  placeholder="Select Run ID"
                  name="workflowRun"
                  value={workflowRun}
                  options={dagRunTimeKeys.map((dagruntime, index) => ({
                    name: moment(dagruntime.split("__")[1])
                      .tz(user.timezone)
                      .format(
                        (user.date_format
                          ? user.date_format
                          : DEFAULT_DATETIME_OPTION) + " h:mm:ss a"
                      ),
                    id: dagruntime,
                  }))}
                  onChange={handleSelectWorkflowRun}
                  required
                />
              </div>
              <div className="workflow-select">
                <h4>Workflow Task ID</h4>
                <Select
                  placeholder="Select Task ID"
                  name="workflowTaskInstance"
                  value={workflowTaskInstance}
                  options={workflowTaskInstances.map((taskInstanceObject) => ({
                    name: taskInstanceObject.task_id,
                    id: taskInstanceObject.task_id,
                  }))}
                  onChange={handleSelectWorkflowTaskInstance}
                  required
                ></Select>
              </div>
              <div className="workflow-select">
                <h4>Workflow Task Try Number</h4>
                <Select
                  placeholder="Select Task Try Number"
                  name="workflow"
                  options={generateTryNumberList(
                    workflowTaskInstanceObject[workflowTaskInstance.value]
                      ?.try_number
                  )}
                  value={workflowTaskTryNumber}
                  onChange={handleSelectWorkflowTaskTryNumber}
                  required
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" value="Submit" className="btn float-right">
              Select
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default TaskDisplay;
